.card-type{
    max-width: 440px;
    
    margin: 20px;
    padding: 20px;
}

.bodyriderhome{
    padding-top: 650px!important;
}
.avartar{
    width:250px;
    height:250px;
}
.titleh2{
    background-color: #3a3a3a;
    color:#f8f8f8;
    text-align: center;
    padding: 10px;
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: 10px;
    
}
.flip-cards-containers{
justify-content: space-between;
}
.justifycontent{
    justify-content: space-between;
}
.color-gree{
    background-color: #04AA6D;
}
.videocontainer{
    justify-content: center;
}
.flip-card {
    background-color: transparent;
    width: 250px;
    height: 320px;
    perspective: 1000px;
    margin: 5px;
    margin-bottom: 30px;

  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  .flip-card-front img {
    width: 150px;
    height: 150px;
  }
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: rgb(230, 230, 230);
    color: black;
    padding-left: 3px;
    padding-right: 3px;
  }
  
  .flip-card-back {
    background-color: #9c27b0;
    padding: 20px;
    color: white;
    transform: rotateY(180deg);
  }
  .plaholderimg{
      margin-top: 10px;
      margin-bottom: 10px;
  }
  
  
 @media only screen and (max-width: 930px){
    .card-type{
        max-width: 350px;
        margin: 20px;
        padding: 20px;
    }
    
    .bodyriderhome {
        padding-top: 150px !important;
    }
    .avartar{
        width:250px;
        height:250px;
    }
    .titleh2{
        background-color: #3a3a3a;
        color:#f8f8f8;
        text-align: center;
        padding: 10px;
        margin-right: -15px;
        margin-left: -15px;
        margin-bottom: 10px;
        
    }
    .flip-cards-containers{
    justify-content: center;
    }
    .justifycontent{
        justify-content: center;
    }
 #card-type01, #card-type02{
     max-width: 350px!important;
     min-width: 300px;
    min-height: 400px;

 }
    .color-gree{
        background-color: #04AA6D;
    }
    .videocontainer{
        justify-content: center;
    }
    .flip-card {
        background-color: transparent;
        width: 350px;
        height: 320px;
        perspective: 1000px;
        margin: 5px;
        margin-bottom: 30px;
    
      }
      
      .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      }
      
      .flip-card:hover .flip-card-inner {
        transform: rotateY(180deg);
      }
      .flip-card-front img {
        width: 150px;
        height: 150px;
      }
      .flip-card-front, .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        font-size: 1.1em;
      }
      
      .flip-card-front {
        background-color: rgb(230, 230, 230);
        color: black;
        padding-left: 3px;
        padding-right: 3px;
      }
      
      .flip-card-back {
        background-color: #9c27b0;
        padding: 20px;
        color: white;
        transform: rotateY(180deg);
      }
      .plaholderimg{
          margin-top: 10px;
          margin-bottom: 10px;
          max-width: 300px!important;
      }
      .react-player{
        width: 90vw!important; 
        height: 350px!important;
      }

      #iframeYoutube{
        width:950px!important; 
        height:650px!important; 
      }
      
}
