.bodyAdmin{
    position: absolute;
    background-color: rgb(225, 225, 225);
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    padding-top: 6vh!important;
    
}

.btn-sidebar{
  border: none;
  background-color: transparent
}
.btn-sidebar:active{
  border: none;
  background-color: transparent
}
.btn-sidebar:focus{
  border: none;
  background-color: transparent
}
// comonentes menu

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');





  .sidebar {
    position: fixed;
    z-index: 9;
    font-family: 'Open Sans', sans-serif;
  }
  .first-menu {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.9) !important;
    height: 100vw;
    width: 83px;
    margin-top: 0px;
    overflow: hidden;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    .list-group-item {
      border-right: 0;
      &:not(last-child) {
        border-bottom: 0;
      }
      &:hover {
        background-color: #f6f6f6;
      }
    }
    a {
      white-space: nowrap;
      color: #8e8e8e;
      font-weight: 400;
      font-size: em(14);
      span {
        opacity: 0;
        visibility: hidden;
    }
    }
    &:hover span {
      opacity: 1;
      visibility: visible;
    }
  }
  .first-menu:hover {
    width: 300px;
  }

  .ocultar{
    width: 83px!important;
    span {
      opacity: 0!important;
      visibility: hidden!important;
    }
    .submenu{
      max-width: 0px!important;
      opacity: 0!important;
    }
  }


  .mostrar{
    width: 300px!important;
    a span {
      opacity: 1!important;
      visibility: visible!important;
    }
    .submenu{
      max-width: 100%!important;
      opacity: 1!important;
    }
  }
  .submenu {
    position: fixed;
    left: 300px;
    width: 300px;
    top: 6vh;
    background-color: #0075BA;
    height: 100vw;
    opacity: 0;
    max-width: 0;
    overflow: auto;
    animation-name: slideOut;
    -webkit-transition-duration: opacity 0.3s;
    -moz-transition-duration: opacity 0.3s;
    -o-transition-duration: opacity 0.3s;
    transition-duration: opacity 0.3s;
    .list-group-item {
      background-color: #0075BA;
      border-left: 0;
      &:hover {
        background-color: #004798;
      }
    }
    a {
      color: #fff;
      font-size: 14px;
      line-height: 1.3;
    }
    span {
      font-weight: 400;
    font-size: 11px;
    color: #e0e0de;
    display: block; 
    }
    
  }
  .svg-submenu {
    background-color: #009eff;
    .list-group-item {
      background-color: #009eff;
      padding-top: 17px;
      padding-bottom: 17px;
      &:hover {
        background-color: #0075BA;
      }
    }
    a {
      line-height: normal;
    }
  }
  .first-menu li:hover .submenu {
    max-width: 100%;
    opacity: 1;
  }


@media only screen and (max-width: 930px){
  .bodyAdmin{
    padding-top: 8vh!important;    
    .submenu {top: 8vh;}
  }
}
@media only screen and (max-width: 930px){
  .bodyAdmin{
    padding-top: 8vh!important;    
   
  } 
    .submenu {
      top: 8vh;
      left: -100px;
  }
}
$width:  calc(100% - 100px);
$height : calc(100% - 80px);
.dashcontenido{
  background-color: #F4F6F8;
  width:  $width;
  height: $height;
  position: relative;
  padding: 10px;
  top:10px;
  margin-left: 85px;
  overflow-y: scroll;

}