#micuentafromIni{
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    min-height: auto;
    padding-bottom: 10px;
    
    }

    #mi-cuentaContent{
        
       // justify-content: center;
        margin: auto;
     
        padding-top: 20px;
        padding-bottom: 70px;
       
    }
    .typeMiniforms{

        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)!important;
        max-width: 450px;
        width: 350px;
        font-size: 14px;
        margin-top: 3px;
        margin-bottom: 3px;
        margin-right: 3px;
    }
    .test{
        max-width:50px;
    }
    .minimalfontzise{
        font-size: 11px;
        line-height: 0.5;
    }

    .dash-columns{
        margin-right: 15px;

    }
    .dash-container-center{
        min-width: 60%;
       
    }
    .userData{
        padding: 10px;
    }
    .distproductos {
        background-color: #fff;
        display: flex;
        padding: 18px;
        position: relative;
      }
      .ui.secondary.menu .active.item {
        box-shadow: none;
        background: rgba(0,0,0,.05);
        color: rgba(0,0,0,.95);
        border-radius: 0.28571429rem;
    }
      
    .ui.secondary.menu .item {
        align-self: center;
        box-shadow: none;
        border: none;
        padding: 0.78571429em 0.92857143em;
        margin: 0 0.35714286em;
        background: 0 0;
        transition: color .1s ease;
        border-radius: 0.28571429rem;
    }
    .ui.menu .active.item {
        background: rgba(0,0,0,.05);
        color: rgba(0,0,0,.95);
        font-weight: 400;
        box-shadow: none;
    }
    .ui.menu:not(.vertical) .item {
        display: flex;
        align-items: center;
        max-width: 150px;
        display: inline-block;
    }
    .pausado{
        font-size: 13px;
        letter-spacing: 1px;
        font-weight: 700;
        padding: 8px 20px;
        margin: 0;
        margin-top: 2px;
        background: #fdf7e7;
        color: #7d0ee6;
        display: inline-block;
        text-transform: uppercase;
        border-radius: 50px;
        text-align: center !important;
    }
    
    .activo{
        font-size: 13px;
        letter-spacing: 1px;
        font-weight: 700;
        padding: 8px 20px;
        margin: 0;
        margin-top: 2px;
        background: #37e240;
        color: #7d0ee6;
        display: inline-block;
        text-transform: uppercase;
        border-radius: 50px;
        text-align: center !important;
    }
    .inactivo{
        font-size: 13px;
        letter-spacing: 1px;
        font-weight: 700;
        padding: 8px 20px;
        margin: 0;
        margin-top: 2px;
        background: #e25f37;
        color: #cdcad1;
        display: inline-block;
        text-transform: uppercase;
        border-radius: 50px;
        text-align: center !important;
    }
    .alignItemscenter{
        text-align: center;
        width: 100%;
    }
    
    .Barwhidth{
        width: 100%;
    }
    .carnetRider{
        width: 250px;
        height: 550px;
        background-image: url("../../../assets/img/fondo.jpg");
        background-color: #cccccc; /* Used if the image is unavailable */
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
    }
    .carnetRiderNofont{
        width: 250px;
        height: 250px;
        background-image: url("../../../assets/img/fondo.jpg");
        background-color: #cccccc; /* Used if the image is unavailable */
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
    }
    .borderCode{
        border: 2px solid #7d0ee6;
        border-radius: 15px;
        padding: 20px;
        background-color: #fff;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
    .centercontent{
        text-align: center!important;
        width: 100%;
    }

    .bottomargin{
        margin-bottom: 20px;
        padding-left: 5px;
        
    }

    .minifront{
        width: 45%;
        display: inline-block;
        position: relative;
        margin:5px;
        
    }
    .contac-data{
        background-color: rgba(255, 255, 255, 0.603);
        padding: 3px;
        
    }
    .contac-data-nofound{
        background-color: rgba(243, 114, 114, 0.603);
        padding: 3px;
        margin-top: 30%;
        margin-bottom: auto;
    }

    #canvas-chartjs-8{
        width: 450px!important;
        height: 100%!important;
    }
    .dash-columns-mini{
        width: 160px!important;
    }
    .cardUploaps{
        max-width: 100%;
        width: 291px;
        font-size: 12px;
        text-align: initial;
    }

    .documentacioncontainer{
        padding: 20px;
        max-width :950px;
    }
   .div-notice{
       margin-top: 20px;
       margin-bottom: 20px;
       padding-top: 20px;
       padding-bottom: 20px;
   }
.max350px{
    max-width: 350px;
}
@media only screen and (max-width: 930px){
    #mi-cuentaContent{
         margin: auto;
         width: 100%;
         padding-top: 20px;
         padding-bottom: 70px;
        
     }
}