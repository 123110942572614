@media only screen and (max-width: 780px) {  
    .hedarwrapper{
    margin-top: -1.25rem;
    height: 15vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    }
    }
.hedarwrapper{
    border: none !important;
    background: none!important;
    margin-top: 0em!important;
    height: 10vh!important;
    .i{
        font-size: 2em!important;
        color: aqua!important;

    }
}

.stylenavlinkbar{
    padding: 0.5rem 0.8rem!important;
    color: #232426!important;
    -webkit-text-decoration: none!important;
    text-decoration: none!important;
    font-weight: 500!important;
}
.stylenavlinkbar:active{
    color: rgb(0,205,246)!important;
}
.stylenavlinkbar:hover{
    color: rgb(0,205,246)!important;
}
