.logo_content{
   // width: 61%;
    max-width: 100px;
    margin-top: 0px;
    width: 100px;
    img{
        width: 100%;
        max-height: 50px;
    
        // transition: all 0.5s;
        // &:hover {
        //     transform: translateX(-15px);
        //   }
    }
}

.logo_content_dash{
 //   width: 61%;
    max-width: 100px;

    img{
        max-width: 100%!important;
        max-height: 50px;
        // transition: all 0.5s;
        // &:hover {
        //     transform: translateX(-15px);
        //   }
    }
}

@media (max-width: 960px){
    .logo_content{
        max-width: 100%;
        max-width: 200px!important;
        height: auto;
        img{
            max-width: 60px!important;
            transition: all 0.5s;
            max-height: 50px;
            &:hover {
                transform: translateX(-15px);
              }
        }
    }
    .logo_content_dash{
        max-width: 200px!important;
        height: auto;
        margin-top: 0px;
        img{
            max-width: 60px!important;
            transition: all 0.5s;
            max-height: 50px;
            &:hover {
                transform: translateX(+3px);
              }
        }
    }

}

@media (max-width: 767px){
    .logo_content{
        max-width: 100%;
        max-width: 100px!important;
        height: auto;
        margin: 0px;
        max-height: 50px;
        img{
            max-width: 100px!important;
            transition: all 0.5s;
            max-height: 50px;
            &:hover {
                transform: translateX(+3px);
              }
        }
    }
    .logo_content_dash{
        max-width: 100px!important;
        height: auto;
        margin-top: 0px;
        max-height: 50px;
        img{
            max-width: 100px!important;
            max-width: 100px!important;
            max-height: 50px;
            transition: all 0.5s;
            &:hover {
                transform: translateX(+3px);
              }
        }
    }
}