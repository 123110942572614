.formcontent{
    text-align: center;
}
.formcontainer{
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(255, 255, 255);
    padding: 20px 20px 20px 20px;
    min-height: 350px;
    max-height: 650px;
    max-width: 350px;
    margin-bottom: 50px;
    box-shadow: -1px 2px 5px 0px rgba(139, 47, 189, 0.75);
    -webkit-box-shadow: -1px 2px 5px 0px rgba(136, 51, 201, 0.75);
    -moz-box-shadow: -1px 2px 5px 0px rgba(159, 39, 189, 0.75);
    border-radius: 5px;
    text-align: left;
}

.formcontainer h2{
    font-size: 18px;
    margin: 5px;
}
#ajustefromIni{
    text-align: left;
}
.equal.width.fields{
    margin: 5px;
}
.brands{
    max-width: 80px;
  
}
.brandsContent{
    width:100%;
}

.brandsStore{
    width: 50%;
}
#bodyFromsRS{
    max-width: 450px!important;
    padding: 20px;
    box-shadow: -1px 2px 5px 0px rgba(139, 47, 189, 0.75);
    -webkit-box-shadow: -1px 2px 5px 0px rgba(136, 51, 201, 0.75);
    -moz-box-shadow: -1px 2px 5px 0px rgba(159, 39, 189, 0.75);   

}
#ajustefromIni > form > div.equal.width.fields > div > div > input[type=email]{
    width: 100%;

}