.cetricas-riders-cards{
    margin-left: 10%; 
    min-width: 300px;
}

.cetricas-riders-cards-title{
    margin-left: 10%; 
}

.cetricas-riders-dash-columns{
    margin: 10px;
    max-width: 30%;
}

.equal.width.fields  {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid grey;
    outline: 0;
    font-size: 1.3rem;

    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;}
  
  input{
      background-color: transparent;
      border: none;
      border-radius: 15px;
      color: gray;
      font-family: inherit;
      font-size: inherit;
      height: 100%;
      outline: none;
      padding-inline-end: 44px;
      padding-inline-start: 52px;
      position: relative;
      width: 100%;
  }
  .carddrivemetric{
      margin-left: 1em;
  }

  .inlineFlexObj{
      display: inline-flex!important;
  }